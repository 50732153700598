<template>
  <v-row class="spam">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Refbook</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by country, operator, MCC or MNC, phone prefix"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn @click="newModal = !newModal" elevation="0" class="white--text ml-5" width="184" height="48" color="primary">
            Add record
          </v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="!data.result.length" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center font-weight-medium mt-8">There is no record that responds to your request</div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="id"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="d-sm-block d-none p-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52">
                  <td>{{ item.country.name }}</td>
                  <td>{{ item.operator }}</td>
                  <td>{{ item.mcc }}</td>
                  <td>{{ item.mnc }}</td>
                  <td>{{ item.min_length }} - {{ item.max_length }}</td>
                  <td>{{ item.prefix }}</td>
                  <td>
                    <div class="d-flex align-center justify-end">
                      <v-btn class="rounded" text icon tile elevation="0" @click="openEdit(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <span class="divider-vertical"></span>
                      <v-btn class="rounded" color="error" text icon tile elevation="0" @click="openDelete(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select v-model="itemsPerPage" :items="itemsPerPageArray" hide-details dense @change="getData"></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
          <div class="d-block d-sm-none">
            <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Country</div>
                <div class="font-weight-medium f18">{{ item.country.name }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Operator/Network</div>
                <div class="font-weight-medium f18">{{ item.operator }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">MCC</div>
                <div class="font-weight-medium f18">{{ item.mcc }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">MNC</div>
                <div class="font-weight-medium f18">{{ item.mnc }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Min/Max</div>
                <div class="font-weight-medium f18">{{ item.min_length }} - {{ item.max_length }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Prefix</div>
                <div class="font-weight-medium f18">{{ item.prefix }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Actions</div>
                <div class="d-flex align-center">
                  <v-btn class="rounded" text icon tile elevation="0" @click="openEdit(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <span class="divider-vertical"></span>
                  <v-btn class="rounded" color="error" text icon tile elevation="0" @click="openDelete(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-divider></v-divider>
            </div>
            <v-row class="my-2 pb-2" align="center" justify="center">
              <span class="grey--text">Rows per page:</span>
              <div class="perPageSelect">
                <v-select
                  v-model="itemsPerPage"
                  :items="itemsPerPageArray"
                  menu-props="auto"
                  hide-details
                  single-line
                  dense
                  @change="getData"
                ></v-select>
              </div>
              <div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="newModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add record</div>
          <v-row>
            <v-col cols="12" class="pa-0 px-3">
              <v-autocomplete
                v-model="newBook.country"
                :items="countryList"
                label="Country"
                :error-messages="countryErrors"
                filled
                outlined
                dense
                required
                item-text="name"
                item-value="id"
                color="input"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-text-field
                v-model="newBook.operator"
                label="Operator/Network"
                placeholder="Enter prefix"
                :error-messages="operatorErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="newBook.mcc"
                label="MCC"
                :error-messages="mccErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pl-md-2 px-3">
              <v-text-field
                v-model="newBook.mnc"
                label="MNC (Optional)"
                :error-messages="mncErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="newBook.min_length"
                label="Min length (Optional)"
                :error-messages="minErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pl-md-2 px-3">
              <v-text-field
                v-model="newBook.max_length"
                label="Max length (Optional)"
                :error-messages="maxErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-text-field
                v-model="newBook.prefix"
                label="Phone prefix"
                placeholder="Enter prefix"
                :error-messages="prefixErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="addBook">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newModal = !newModal">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Edit record</div>
          <v-row>
            <v-col cols="12" class="pa-0 px-3">
              <v-autocomplete
                v-model="editedItem.country"
                :items="countryList"
                label="Country"
                :error-messages="countryErrors"
                filled
                outlined
                dense
                required
                item-text="name"
                item-value="id"
                color="input"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-text-field
                v-model="editedItem.operator"
                label="Operator/Network"
                :error-messages="operatorErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="editedItem.mcc"
                label="MCC"
                :error-messages="mccErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pl-md-2 px-3">
              <v-text-field
                v-model="editedItem.mnc"
                label="MNC (Optional)"
                :error-messages="mncErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="editedItem.min_length"
                label="Min length (Optional)"
                :error-messages="minErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pl-md-2 px-3">
              <v-text-field
                v-model="editedItem.max_length"
                label="Max length (Optional)"
                :error-messages="maxErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-text-field
                v-model="editedItem.prefix"
                label="Phone prefix"
                placeholder="Enter prefix"
                :error-messages="prefixErrors"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="updateBook">Save</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Remove the refbook</div>
          <div class="mt-3 opasity--text">Do you really want to remove the refbook {{ editedItem.country.name }}</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="remove">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      newModal: false,
      editModal: false,
      deleteModal: false,
      editedItem: { country: {} },
      newBook: { country: {} },
      search: this.$route.query.src || '',
      itemsPerPage: +this.$route.query.page_size || 10,
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Country', value: 'country', sortable: false },
        { text: 'Operator/Network', value: 'operator', sortable: false },
        { text: 'MCC', value: 'mcc', sortable: false },
        { text: 'MNC', value: 'mnc', sortable: false },
        { text: 'Min/Max', value: 'min', sortable: false },
        { text: 'Prefix', value: 'prefix', sortable: false },
        { text: 'Actions', align: 'end', value: 'actions', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getCoutries');
  },
  methods: {
    notifi(btn) {
      if (btn == 'created') {
        this.$notify({ message: 'Refbook has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Refbook has been upadate', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          ype: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    openEdit(item) {
      this.editedItem = Object.assign({}, item);
      this.editModal = true;
    },
    openDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteModal = true;
    },
    closeDialog() {
      this.editModal = false;
      this.deleteModal = false;
      this.error = [];
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, { country: {} });
      });
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getRefList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setRefList', {});
      } else if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.status >= 400 && e.response.status < 500) {
        e.response.data.error[0] === 'src__invalid' ? this.notifi('search400') : this.notifi('400');
      } else if (e.response.status >= 500) {
        this.notifi('500');
      }
    },
    async addBook() {
      this.error = [];
      const data = new Object();
      data.country = this.newBook.country;
      data.operator = this.newBook.operator;
      data.mcc = +this.newBook.mcc;
      data.mnc = +this.newBook.mnc;
      data.prefix = this.newBook.prefix;
      this.newBook.min_length ? (data.min_length = +this.newBook.min_length) : '';
      this.newBook.max_length ? (data.max_length = +this.newBook.max_length) : '';
      await this.$store
        .dispatch('createRefBook', data)
        .then(() => {
          this.newModal = false;
          this.notifi('created');
          this.getData();
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async remove() {
      await this.$store.dispatch('deleteRefBook', this.editedItem.id).then(() => {
        this.closeDialog();
        this.getData();
      });
    },
    async updateBook() {
      this.error = [];
      const data = new Object();
      data.country = this.editedItem.country;
      data.operator = this.editedItem.operator;
      data.mcc = +this.editedItem.mcc;
      data.mnc = +this.editedItem.mnc;
      data.prefix = this.editedItem.prefix;
      this.editedItem.min_length ? (data.min_length = +this.editedItem.min_length) : '';
      this.editedItem.max_length ? (data.max_length = +this.editedItem.max_length) : '';
      await this.$store
        .dispatch('updateRefBook', {
          id: this.editedItem.id,
          data: data,
        })
        .then(() => {
          this.closeDialog();
          this.notifi('update');
          this.getData();
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    newModal() {
      this.error = [];
      this.newBook = { country: {} };
    },
    editModal(val) {
      val || this.closeDialog();
    },
    deleteModal(val) {
      val || this.closeDialog();
    },
  },
  computed: {
    data() {
      return this.$store.getters.refBookList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    countryList() {
      return this.$store.getters.countries;
    },
    countryErrors() {
      const errors = [];
      this.error.find((item) => item == 'country__required') && errors.push('Please enter country');
      this.error.find((item) => item == 'country__invalid') && errors.push('Provided country is not valid');
      return errors;
    },
    operatorErrors() {
      const errors = [];
      this.error.find((item) => item == 'operator__required') && errors.push('Please enter operator');
      this.error.find((item) => item == 'operator__invalid') && errors.push('Provided operator is not valid');
      return errors;
    },
    mccErrors() {
      const errors = [];
      this.error.find((item) => item == 'mcc__required') && errors.push('Please enter mcc');
      this.error.find((item) => item == 'mcc__invalid') && errors.push('Provided mcc is not valid');
      return errors;
    },
    mncErrors() {
      const errors = [];
      this.error.find((item) => item == 'mnc__required') && errors.push('Please enter mnc');
      this.error.find((item) => item == 'mnc__invalid') && errors.push('Provided mnc is not valid');
      return errors;
    },
    prefixErrors() {
      const errors = [];
      this.error.find((item) => item == 'prefix__required') && errors.push('Please enter prefix');
      this.error.find((item) => item == 'prefix__invalid') && errors.push('Provided prefix is not valid');
      return errors;
    },
    maxErrors() {
      const errors = [];
      this.error.find((item) => item == 'max_length__invalid') && errors.push('Provided max length is not valid');
      return errors;
    },
    minErrors() {
      const errors = [];
      this.error.find((item) => item == 'min_length__invalid') && errors.push('Provided min length is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setRefList', {});
    this.$store.dispatch('setCoutries', []);
  },
};
</script>
